import { forEach, isArray, map, some } from 'lodash';

export const addUniqueScaling = (options) => {
    const opt = { ...options };
    opt.yAxis = [{ ...opt.yAxis[0], opposite: false }];
    opt.series = map(opt.series, (s) => {
        return { ...s, yAxis: opt.yAxis[0].id };
    });

    return opt;
};

export const hasDataSeries = chartOptions => chartOptions?.series?.length &&
    some(chartOptions.series, item => item.data?.length > 0);

export const addCustomZoom = (options, selectionCallbackFn) => {
    const newOptions = { ...options };

    if (newOptions.chart) {
        newOptions.chart.resetZoomButton = {
            theme: {
                style: {
                    display: 'none'
                }
            }
        };
        newOptions.chart.events = {
            ...newOptions.chart.events,
            selection: selectionCallbackFn
        };
        newOptions.xAxis.minRange = 2;
    }
    return newOptions;
};

const getExtremesMinMax = (ax) => {
    if (ax) {
        const { min, max } = ax.getExtremes();
        return [min, max];
    }
    return [null, null];
};

const cloneExtremesWithoutRedraw = (dstAx, srcAx) => {
    const [min, max] = getExtremesMinMax(srcAx);
    dstAx.setExtremes(min, max, false, false);
};

export const saveSeriesSelection = (destChart, srcChart) => {
    if (!destChart || !srcChart) {
        return;
    }

    if (!Array.isArray(destChart.series) || !Array.isArray(srcChart.series) || destChart.series.length === 0 || srcChart.series.length === 0) {
        return;
    }

    destChart?.series[0]?.hide();
    forEach(destChart?.series, (destSeries, idx) => {
        const srcSeries = srcChart.series[idx];
        if (destSeries.visible !== srcSeries.visible) {
            srcSeries.visible ? destSeries.show() : destSeries.hide();
        }
    });
};

export const setChartBounds = (destAxis, srcAxis = null) => {
    if (!destAxis) {
        return null;
    }
    if (isArray(destAxis)) {
        for (let i = 0; i < destAxis.length; i++) {
            cloneExtremesWithoutRedraw(destAxis[i], srcAxis && srcAxis[i]);
        }
    }
    else if (destAxis) {
        cloneExtremesWithoutRedraw(destAxis, srcAxis);
    }
};

export const updateZoomLevel = (destChart, srcChart, data) => {
    if (data === false) {
        setChartBounds(destChart?.xAxis, srcChart?.xAxis);
        destChart?.redraw(false);

    } else {
        setChartBounds(destChart?.yAxis, srcChart?.yAxis);
        setChartBounds(destChart?.xAxis, srcChart?.xAxis);
        destChart?.redraw(false);
    }
};
