import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import './style.scss';
import { isEqual } from 'lodash';


const ToggleButtonComponent = ({
	options,
	selectedValue,
	onSelected,
	prefixIcon,
	prefixIconSelected,
	buttonProps
}) => {
	const [toggleValue, setToggleValue] = useState(selectedValue);

	const renderPrefixIcon = (option) => {
		return toggleValue === option.value
			? option.prefixIconSelected || prefixIconSelected
			: option.prefixIcon || prefixIcon;
	};

	const handleToggle = (clickedValue) => {
		setToggleValue(clickedValue);
		if (onSelected) {
			onSelected(clickedValue);
		}
	};

	useEffect(() => {
		setToggleValue(selectedValue);
	}, [selectedValue]);

	return (
		<div className='toggle-button-container' role='toggle-button'>
			{map(options, (option, key) => {
				return <button
					{...buttonProps}
					role='option'
					selected={isEqual(toggleValue, option.value)}
					aria-valuetext={option.label}
					aria-selected={isEqual(toggleValue, option.value) ? 'true' : 'false'}
					key={key}
					onClick={(event) => { handleToggle(option.value); event.stopPropagation(); }}
					className={`toggle-button ${isEqual(toggleValue, option.value) && 'active'}`}
				>
					{renderPrefixIcon(option)}
					<div className='toggle-text'>{option.label}</div>
				</button>;
			})}
		</div>
	);
};

ToggleButtonComponent.propTypes = {
	onSelected: PropTypes.func,
	selectedValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.object
			]),
			label: PropTypes.string,
			prefixIcon: PropTypes.any,
			prefixIconSelected: PropTypes.any
		})
	),
	prefixIcon: PropTypes.any,
	prefixIconSelected: PropTypes.any,
	forbiddenReselect: PropTypes.bool
};

export default ToggleButtonComponent;
