import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconDropUpMo, IconDropDownMo } from 'svgIcons/MotionPortalIcons';

import './_style.scss';
import { useContentSize } from 'sharedHooks';


const ExpandableComponent = ({
    id = '',
    onClick,
    open,
    noHeader,
    title,
    children,
    rightArrow,
    expanded,
    setIsExapnded,
    setExpanded,
    disabled = false,
    kpiCount = 0,
    isExpanderInsideContent = false
}) => {
    const [expandableOpen, setExpandableStatus] = useState(false);


    const ref = useRef(null);
    const { height } = useContentSize(ref);

    const toggleExpandable = () => {
        if (!disabled) {
            setExpandableStatus(!expandableOpen);
        }
    };

    const handleClick = () => {
        if (!disabled) {
            toggleExpandable();
            if (onClick) {
                onClick();
            }
        }
    };

    const renderHeader = () =>
        !noHeader &&
        <button
            className={`header ${rightArrow ? 'right-arrow' : null}`}
            key={id}
            onClick={handleClick}
            role='expandable-header'
        >
            <div className='header__left-pane'>
                {!isExpanderInsideContent &&
                    <div className='open-close-container'>
                        {expandableOpen ? <IconDropUpMo /> : <IconDropDownMo />}
                    </div>
                }
                <div className='title-content'>
                    {isExpanderInsideContent &&
                        <div className={`open-close-container ${isExpanderInsideContent ? 'enable-sticky' : ''}`}>
                            {expandableOpen ? <IconDropUpMo /> : <IconDropDownMo />}
                        </div>
                    }
                    {title}
                </div>
            </div>
            {kpiCount > 0 &&
                <div className='header__right-pane'>
                    <div className='count-indicator count-indicator--active'>{!expandableOpen ? `+ ${kpiCount}` : ''}</div>
                </div>
            }
        </button>;

    const renderContent = () => {
        return <div className='expandable-content' style={{ maxHeight: height }} >
            <div ref={ref} >
                {expandableOpen ? children : null}
            </div>
        </div>;

    };

    useEffect(() => {
        // force open flag to evaluate to boolean
        if (!!open !== expandableOpen) {
            toggleExpandable();
        }
    }, [open]);

    useEffect(() => {
        if (!expanded && expandableOpen) {
            setExpandableStatus(false);
            setIsExapnded(false);
            setExpanded(false);
        }
    }, [expanded]);

    return <div id={id} className='expandable-component' >
        {renderHeader()}
        {renderContent()}
    </div>;
};

ExpandableComponent.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    open: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    noHeader: PropTypes.bool,
    disabled: PropTypes.bool,
    kpiCount: PropTypes.number,
    isExpanderInsideContent: PropTypes.bool
};

export default ExpandableComponent;
