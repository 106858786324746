import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { IconDatePicker } from 'svgIcons/MotionPortalIcons';
import {
    getUTCDateString,
    getDateRangeString,
    compareDatesToDayPrecision,
    getNrOfDaysBetween
} from 'helpers/dateHelper';
import PredefinedSelectionsWrapper from './PredefinedSelectionsWrapper';
import withVisibilityControl from './withVisibilityControl';
import clsx from 'clsx';
import { translate } from 'helpers/translateHelper';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import './_style.scss';


const DateSelector = ({
    visibilityControl,
    start,
    end,
    handleSelectDate: customSelectionHandler,
    numberOfMonths = 1,
    maxDaysBack,
    maxDaysRange,
    minimumRequiredDaysRange,
    children,
    intervalText,
    showNumberOfDays = true,
    footerIntervalText,
    dateSelectButtonText = translate('ABB.Powertrain.Frontend.applyBtnLbl'),
    dateCancelButtonText = translate('ABB.Powertrain.Frontend.cancelLabel'),
    disableFuture = true,
    disabled = false
}, ref) => {
    const { t: translate } = useTranslation();
    const [from, setFrom] = useState(new Date(start));
    const [to, setTo] = useState(end && end !== '' ? new Date(end) : new Date());
    const [predefinedRange, setPredefinedRange] = useState(0);
    const [screenOverflow, setScreenOverflow] = useState(false);
    const [visibleMonth, setVisibleMonth] = useState(from || new Date());
    const [disableSelectDateButton, setDisableSelectDateButton] = useState(true);

    useEffect(() => {
        if (!from || !to) {
            setDisableSelectDateButton(true);
            return;
        }
        setDisableSelectDateButton(compareDatesToDayPrecision(start, from) && compareDatesToDayPrecision(end, to));
    }, [from, to, start, end]);

    useEffect(() => {
        if (visibilityControl.visible && ref?.current) {
            setScreenOverflow(true);
            const { width } = ref.current.getBoundingClientRect();
            const { x } = ref.current.parentElement?.getBoundingClientRect() || ref.current.getBoundingClientRect();
            const elementIsOverflowing = x + width >= window.innerWidth;
            setScreenOverflow(elementIsOverflowing);
        }

        if (!visibilityControl.visible) {
            setFrom(new Date(start));
            setTo(new Date(end));
        }
    }, [visibilityControl.visible]);

    /**
  * In case after setting 'from', the interval exceeds the maxDaysRange limit
  * sets the end of the interval to fit
  */
    useEffect(() => {
        if (from) {
            if (typeof maxDaysRange === 'number' && maxDaysRange >= 1 && from && to) {
                const maxTo = new Date(from);
                maxTo.setDate(from.getDate() + maxDaysRange);
                if (to.getTime() > maxTo.getTime()) {
                    setTo(new Date(maxTo.setHours(23, 59, 59, 999)));
                }
            }
            const distance = Math.abs(from.getMonth() - visibleMonth.getMonth());

            if (distance >= 0) {
                if (from > visibleMonth && distance > 0 || from < visibleMonth && distance > 0 || distance === 0) {
                    setVisibleMonth(from);
                }
            }
        }

    }, [from]);


    useEffect(() => {
        setFrom(new Date(start));
        setTo(new Date(end));
    }, [start, end]);

    const handleDayClick = (day) => {
        if (!from && !to) {
            setFrom(new Date(new Date(day).setHours(0, 0, 0, 0)));
            return;
        }
        if (from && to && from <= day && day <= to) {
            setFrom(null);
            setTo(null);
            return;
        } else if (from && to) {
            setFrom(new Date(new Date(day).setHours(0, 0, 0, 0)));
            setTo(null);
            return;
        }
        if (from && !to) {
            const newDate = new Date(new Date(day).setHours(23, 59, 59, 999));
            if (newDate < from) {
                setFrom(new Date(new Date(day).setHours(0, 0, 0, 0)));
            } else {
                setTo(new Date(new Date(day).setHours(23, 59, 59, 999)));
            }
            return;
        }
    };

    /**
     * When an interval is selected, handle it with outside function
     */
    const handleDateSelection = () => {
        // redefinedRange is 0 only if there is no 'children' or custom range was selected after
        // Having it other than 0 means that the user has selected one of the predef. ranges and hit 'Select date' right after
        const formattedFrom = getUTCDateString(from);
        const formattedTo = getUTCDateString(to);

        customSelectionHandler(formattedFrom, formattedTo, predefinedRange !== 0 ? predefinedRange : null);

        visibilityControl.setVisibilityOff();
    };

    const resolveDisableFuture = () => {
        const disabledDays = [];

        // Disable the selection of future dates
        const endOfThisDay = new Date();
        endOfThisDay.setHours(23, 59, 59, 999);
        disabledDays.push({ after: endOfThisDay });

        // Disable selection of dates too far in history
        if (typeof maxDaysBack === 'number' && maxDaysBack >= 1) {
            const firstAvailableDay = new Date(endOfThisDay);
            firstAvailableDay.setDate(endOfThisDay.getDate() - maxDaysBack + 1);
            disabledDays.push({ before: firstAvailableDay });
        }

        // Disable selection of too long date ranges
        if (typeof maxDaysRange === 'number' && maxDaysRange >= 1) {
            if (from && to) {
                const maxDaysFromDate = new Date(from);
                maxDaysFromDate.setDate(from.getDate() + maxDaysRange - 1);
                disabledDays.push({ after: maxDaysFromDate });

                const maxDayToDate = new Date(to);
                maxDayToDate.setDate(to.getDate() - maxDaysRange + 1);
                disabledDays.push({ before: maxDayToDate });
            } else if (from) {
                const maxDaysFromDate = new Date(from);
                maxDaysFromDate.setDate(from.getDate() + maxDaysRange - 1);
                disabledDays.push({ after: maxDaysFromDate });

                const maxDayToDate = new Date(from);
                maxDayToDate.setDate(from.getDate() - maxDaysRange + 1);
                disabledDays.push({ before: maxDayToDate });
            }
        }

        return disabledDays;
    };

    const resolveDisablePast = () => {
        const disabledDays = [];

        // Disable the selection of future dates
        const now = new Date();
        disabledDays.push({ before: now });

        return disabledDays;
    };

    const selectPreviousDateRangeFromToday = (numberOfDaysBack, numberOfHoursBack = null) => {
        const startDate = new Date();

        const priorStartDate = numberOfHoursBack
            ? new Date(startDate.setHours(startDate.getHours() - numberOfHoursBack))
            : new Date(new Date(startDate.setDate(startDate.getDate() - numberOfDaysBack + 1)).setHours(0, 0, 0, 0));

        setFrom(priorStartDate);
        setTo(numberOfHoursBack ? new Date() : new Date((new Date).setHours(23, 59, 59, 999)));
        setPredefinedRange(numberOfDaysBack);
        setVisibleMonth(priorStartDate);
    };

    const modifiers = { start: from, end: to };

    const nrOfSelectedDays = getNrOfDaysBetween(from, to);

    return (
        <div className='calendar-interval-container' role='dateSelector'>
            {intervalText ?
                <div className='dateInterval' >
                    {intervalText}
                </div>
                : null}
            <div
                className={`calendar-circle-wrapper ${disabled ? 'disabled' : ''}`}
                role='iconButton'
                onClick={disabled ? () => { } : visibilityControl.setVisibilityOn}
            >
                <span className='date-text'>{getDateRangeString(start, end)}</span>
                <span className='date-selector-icon'>
                    <IconDatePicker className='calendar-icon' />
                </span>
            </div>
            {visibilityControl.visible &&
                <div ref={ref} className={clsx(
                    'date-selector-container',
                    screenOverflow ? 'date-selector-from-right-corner' : 'date-selector-from-left-corner',
                    children ? '' : 'narrow'
                )}>
                    <div className='date-selector-top-container'>
                        <DayPicker
                            className='Selectable'
                            numberOfMonths={numberOfMonths}
                            month={visibleMonth}
                            selected={{ from, to }}
                            modifiers={modifiers}
                            onDayClick={handleDayClick}
                            disabled={disableFuture ? resolveDisableFuture() : resolveDisablePast()}
                            mode='range'
                            min={minimumRequiredDaysRange}
                            onMonthChange={setVisibleMonth}
                        />
                        {children && <PredefinedSelectionsWrapper
                            predefinedRange={predefinedRange}
                            selectionHandler={selectPreviousDateRangeFromToday}>
                            {children}
                        </PredefinedSelectionsWrapper>}
                    </div>
                    <div className='date-selector-bottom-container'>
                        <span className='footer-interval-text-container'>
                            {showNumberOfDays ? <div className='selected-days'>{
                                `${nrOfSelectedDays} ${translate('ABB.Powertrain.Frontend.datePickerDaysSelectedLbl')}`
                            }</div> : null}
                            {footerIntervalText ? <div className='footer-description'>{footerIntervalText}</div> : null}
                        </span>
                        <GeneralButton
                            type='discreet'
                            className='date-cancel-button'
                            text={dateCancelButtonText}
                            onClick={() => visibilityControl.setVisibilityOff()}
                        />
                        <GeneralButton
                            type='primary'
                            disabled={disableSelectDateButton}
                            text={dateSelectButtonText}
                            onClick={!disableSelectDateButton ? handleDateSelection : null}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

const DateSelectorWithForwardRef = forwardRef(DateSelector);

DateSelector.propTypes = {
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
    handleSelectDate: PropTypes.func.isRequired,
    numberOfMonths: PropTypes.number,
    maxDaysBack: PropTypes.number,
    maxDaysRange: PropTypes.number,
    minimumRequiredDaysRange: PropTypes.number,
    visibilityControl: PropTypes.shape({
        visible: PropTypes.bool.isRequired,
        setVisibilityOn: PropTypes.func.isRequired,
        setVisibilityOff: PropTypes.func.isRequired
    }),
    intervalText: PropTypes.string,
    showNumberOfDays: PropTypes.bool,
    footerIntervalText: PropTypes.string,
    dateSelectButtonText: PropTypes.string,
    dateCancelButtonText: PropTypes.string,
    disableFuture: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withVisibilityControl(DateSelectorWithForwardRef);
