import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GeneralDialog } from 'sharedComponents';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { Row, Col } from 'react-bootstrap';

import Chart from './Chart';
import useCustomZoom from './useCustomZoom';
import useCustomizedOptions from './useCustomizedOptions';
import useChartInstance from './useChartInstance';

import './styles.scss';
import { translate } from 'helpers/translateHelper';
import { DefaultHeader } from './headers';
import { saveSeriesSelection, updateZoomLevel } from './helpers';
import { apiCallStatus } from 'helpers/constants';


const PopOutChart = (props) => {
    const [showChartPopout, setChartPopOut] = useState(false);
    const [uniqueScaling, setUniqueScaling] = useState(false);
    const [mainChartInstance, mainChartCallback, mainChartDestroyCallback] = useChartInstance(props.callback);
    const [popoutChartInstance, popOutChartCallback, popOutChartDestroyCallback] = useChartInstance(props.callback);
    const [zooming, setZooming] = useState();
    const zoomingStatus = zooming ? apiCallStatus.LOADING : apiCallStatus.SUCCESS;

    const [selectionCallbackFn] = useCustomZoom(
        props,
        mainChartInstance,
        popoutChartInstance,
        showChartPopout,
        props.zoomOut || ((chartInst) => chartInst?.zoomOut()),
        setZooming
    );

    const [chartOptions] = useCustomizedOptions(props.options, props.enableUniqueScaling, uniqueScaling, selectionCallbackFn);

    const renderChart = (container, callbackFn, destroyFn) => {
        if (!props.hasKpi && props.renderNoKpi) {
            return props.renderNoKpi();
        }

        if (!props.hasData && props.renderNoData) {
            return props.renderNoData();
        }

        return <Chart
            options={chartOptions}
            container={container}
            modules={props.modules}
            callback={callbackFn}
            destroy={destroyFn}
        />;
    };

    const MainChartHeader = props.mainChartHeader || DefaultHeader;
    const PopOutChartHeader = props.popOutChartHeader ? props.popOutChartHeader : MainChartHeader;

    return <>
        <MainChartHeader
            {...props}
            mainChartInst={mainChartInstance}
            uniqueScaling={uniqueScaling}
            setUniqueScaling={setUniqueScaling}
            chartOptions={chartOptions}
            showChartPopout={showChartPopout}
            setChartPopOut={setChartPopOut}
            labelLastSync={props.labelLastSync || translate('ABB.Powertrain.Frontend.labelLastSync')}
            setZooming={setZooming}
        />
        <Row className='popout-chart-row chart-wrapper-row'>
            <Col>
                <div className='chart-container'>
                    {renderChart(props.container, mainChartCallback, mainChartDestroyCallback)}
                </div>
            </Col>
        </Row>
        <GeneralDialog
            modalClassName='popout-chart-modal'
            show={showChartPopout}
            close={() => {
                if (props.loadingStatus && props.loadingStatus !== apiCallStatus.LOADING || props.memorizePopoutChart) {
                    saveSeriesSelection(mainChartInstance, popoutChartInstance);
                    updateZoomLevel(mainChartInstance, popoutChartInstance, props.data);
                }
                setChartPopOut(false);
            }}
            closeButton={true}
        >
            <div className='chart-container popout-chart-container' style={props.popOutContainerCustomStyle}>
                <LoadingStatusWrapper useCustomAnchor loadingStatus={[props.loadingStatus, zoomingStatus]} transparent>
                    <PopOutChartHeader
                        {...props}
                        chartOptions={chartOptions}
                        showChartPopout={showChartPopout}
                        setChartPopOut={setChartPopOut}
                        uniqueScaling={uniqueScaling}
                        setUniqueScaling={setUniqueScaling}
                        hideSelect={true}
                        labelLastSync={props.labelLastSync || translate('ABB.Powertrain.Frontend.labelLastSync')}
                    />
                    {renderChart(`${props.container}-pop-out`, popOutChartCallback, popOutChartDestroyCallback)}
                    {props.popOutFooter}
                </LoadingStatusWrapper>
            </div>
        </GeneralDialog>
    </>;
};

PopOutChart.propTypes = {
    callback: PropTypes.func,
    container: PropTypes.any,
    hasKpi: PropTypes.bool,
    loadingStatus: PropTypes.string,
    modules: PropTypes.array,
    options: PropTypes.object,
    renderNoKpi: PropTypes.func,
    setIsZoomed: PropTypes.func.isRequired,
    isZoomed: PropTypes.bool.isRequired,
    zoomInServer: PropTypes.func,
    resetServerZoom: PropTypes.func,
    enableUniqueScaling: PropTypes.bool,
    hasData: PropTypes.bool,
    renderNoData: PropTypes.func,
    memorizePopoutChart: PropTypes.bool,
};

export default PopOutChart;
