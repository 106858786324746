import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import './style.scss';

const ArrowSeparator = () => {
    return (
        <span className='breadcrumb__separator'>
            <svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M4.7 4L0.7 8L0 7.3L3.29 4L0 0.7L0.7 0L4.7 4Z' fill='#696969' />
            </svg>
        </span>
    );
};

const Breadcrumbs = ({ routes, onBreadcrumbClick, options, selectedAssetName }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const history = useHistory();
    const hasOptions = options && options.length > 0;
    const maxLength = 50;
    useEffect(() => {
        if (hasOptions && selectedAssetName) {
            const currentOption = options.find(opt => opt.value === selectedAssetName);
            setSelectedOption(currentOption || options[0]);
        } else if (hasOptions) {
            setSelectedOption(options[0]);
        }
    }, [options, selectedAssetName]);

    const handleBreadcrumbClick = (path) => {
        if (onBreadcrumbClick) {
            onBreadcrumbClick(path);
        }
    };

    const handleSelectChange = (option) => {
        setSelectedOption(option);
        if (option && option.route) {
            const currentLocation = history.location;
            const newSearch = new URLSearchParams(currentLocation.search);

            const optionSearch = typeof option.route.search === 'string'
                ? new URLSearchParams(option.route.search)
                : option.route.search;

            const assetId = optionSearch.get('assetId');
            if (assetId) {
                newSearch.set('assetId', assetId);
            }

            history.push({
                pathname: currentLocation.pathname,
                search: newSearch.toString(),
                state: { ...currentLocation.state, selectedAsset: option.value }
            });
        }
    };

    return (
        <Breadcrumb className='breadcrumb'>
            {routes.map((route, index) => {
                const isLastItem = index === routes.length - 1;
                return (
                    <React.Fragment key={route.name}>
                        <BreadcrumbItem className={`breadcrumb__item ${isLastItem && !hasOptions ? 'breadcrumb__item--active' : ''}`}>
                            {isLastItem && !hasOptions ? route.name :
                                <Link
                                    to={typeof route.path === 'object' ? route.path : { pathname: route.path }}
                                    className='breadcrumb__link'
                                    onClick={() => handleBreadcrumbClick(route.path)}
                                >
                                    {route.name}
                                </Link>
                            }
                            {route.name && (index < routes.length - 1 || hasOptions) && <ArrowSeparator />}
                        </BreadcrumbItem>
                    </React.Fragment>
                );
            })}
            {hasOptions &&
                <BreadcrumbItem active className='breadcrumb__active'>
                    <Select
                        options={options}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        placeholder='Search...'
                        isSearchable={true}
                        onInputChange={inputValue =>
                            inputValue.length <= maxLength ? inputValue : inputValue.substring(0, maxLength)
                        }
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: '30px',
                                border: 'none',
                                boxShadow: 'none',
                                background: 'transparent',
                                width: '100%',
                            }),
                            valueContainer: (base) => ({
                                ...base,
                                padding: '0 6px',
                                whiteSpace: 'normal',
                                height: 'auto',
                            }),
                            singleValue: (base) => ({
                                ...base,
                                maxWidth: '100%',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                            }),
                            input: (base) => ({
                                ...base,
                                margin: '0px',
                            }),
                            indicatorSeparator: () => ({
                                display: 'none',
                            }),
                            dropdownIndicator: (base) => ({
                                ...base,
                                padding: 4,
                            }),
                            menu: (base) => ({
                                ...base,
                                width: '250px'
                            }),
                        }}
                    />
                </BreadcrumbItem>
            }
        </Breadcrumb>
    );
};

Breadcrumbs.propTypes = {
    routes: PropTypes.array.isRequired,
    onBreadcrumbClick: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        route: PropTypes.string
    }))
};

export default Breadcrumbs;
