import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { DateSelector, PredefinedSelection } from 'sharedComponents';
import { getInitialStartDate, getInitialEndDate } from './helpers';
import { allPredefinedDaySelections, allPredefinedDaySelections365 } from './constants';

import './styles.scss';

const TimeSelectorRow = ({
    handleSelectDate,
    fromDate,
    toDate,
    preSelectedDays,
    minimumRequiredDaysRange = 0,
    label,
    numberOfMonths,
    maxDaysRange,
    maxDaysBack,
    dateSelectButtonText,
    dateCancelButtonText,
    showNumberOfDays,
    footerIntervalText,
    withPredefinedSelection = true,
    disableFuture = true,
    disabled = false
}) => {
    const { t: translate } = useTranslation();
    const onHandleSelectDate = (from, to) => {
        if (from && to) {
            handleSelectDate(from, to);
        }
    };

    useEffect(() => {
        if (!fromDate || !toDate) {
            let from = fromDate;
            if (!from) {
                from = getInitialStartDate(preSelectedDays);
            }
            let to = toDate;
            if (!to) {
                to = getInitialEndDate();
            }
            onHandleSelectDate(from, to);
        }
    }, []);

    if (!fromDate || !toDate) {
        return null;
    }
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const getPredefinedSelections = () => {
        return maxDaysRange === 365 ? allPredefinedDaySelections365.filter((dayInterval) => minimumRequiredDaysRange <= dayInterval.numberOfDaysBack)
            .map((dayInterval, index) =>
                <PredefinedSelection
                    key={index}
                    label={translate(dayInterval.label)}
                    numberOfDaysBack={dayInterval.numberOfDaysBack}
                    numberOfHoursBack={dayInterval.numberOfHoursBack} />
            ) : allPredefinedDaySelections.filter((dayInterval) => minimumRequiredDaysRange <= dayInterval.numberOfDaysBack)
                .map((dayInterval, index) =>
                    <PredefinedSelection
                        key={index}
                        label={translate(dayInterval.label)}
                        numberOfDaysBack={dayInterval.numberOfDaysBack}
                        numberOfHoursBack={dayInterval.numberOfHoursBack} />);
    };

    return <Row className='time-selector-row'>
        <Col className='time-selector-row-content'>
            <div className='range-text'>
                <span>{label}</span>
                <DateSelector
                    start={from}
                    end={to}
                    handleSelectDate={onHandleSelectDate}
                    numberOfMonths={numberOfMonths || 2}
                    maxDaysRange={maxDaysRange}
                    maxDaysBack={maxDaysBack}
                    scrollToTopWhenOpened={false}
                    dateSelectButtonText={dateSelectButtonText}
                    dateCancelButtonText={dateCancelButtonText}
                    showNumberOfDays={showNumberOfDays}
                    footerIntervalText={footerIntervalText || ''}
                    minimumRequiredDaysRange={minimumRequiredDaysRange}
                    disableFuture={disableFuture}
                    disabled={disabled}
                >
                    {withPredefinedSelection && getPredefinedSelections()}
                </DateSelector>
            </div>
        </Col>
    </Row >;
};

TimeSelectorRow.propTypes = {
    fromDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
    toDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
    handleSelectDate: PropTypes.func.isRequired,
    maxDaysRange: PropTypes.number,
    maxDaysBack: PropTypes.number,
    minimumRequiredDaysRange: PropTypes.number,
    preSelectedDays: PropTypes.number,
    label: PropTypes.string,
    numberOfMonths: PropTypes.number,
    dateSelectButtonText: PropTypes.string,
    dateCancelButtonText: PropTypes.string,
    footerIntervalText: PropTypes.string,
    showNumberOfDays: PropTypes.bool,
    withPredefinedSelection: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disabled: PropTypes.bool
};

export default TimeSelectorRow;
